// import { useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
// import { useQuery, gql } from '@apollo/client';

import "./styles/App.less"
import Routes, { RouteWithSubRoutes } from "./router/Routes"

// 主程序入口



function App() {
    // const GET_USERS = gql`
    //   query {
    //       allUsers {
    //         name
    //       }
    // }
    // `;
    // const { loading, error, data } = useQuery(GET_USERS);
    //
    // useEffect(()=> {
    //     console.log('999', 999)
    // }, [])
    //
    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error : {error.message}</p>;


    return (
        <Router>
            <Switch>
                {Routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
            </Switch>
        </Router>

    );
}

export default App;
