// import React from "react";
import { render } from "react-dom";
import ErrorHandleComponent from "@/components/ErrorHandleComponent"
// -----antd start -----
// antd 配置中文
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
// -----antd end -----
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

// ------ apollo client -------
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
    uri: '/api',
    cache: new InMemoryCache(),
});

render(
    <ApolloProvider client={client}>
        <ConfigProvider locale={zhCN}>
            <ErrorHandleComponent>
                <App />
            </ErrorHandleComponent>
        </ConfigProvider>
    </ApolloProvider>
    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
