import { Component } from "react"
export default class ErrorHandleComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    // 会引起热更新不断的更新组件报错 页面卡死 index.js:1 loadable-components: failed to asynchronously load component
    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     // logErrorToMyService(error, errorInfo);
    //     // return { hasError: true };
    // }

    componentDidCatch(error, errorInfo) {
        console.log(error)
        console.log(errorInfo)
        // Log 日志
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return <h1>Something went wrong.</h1>;
        // }

        return this.props.children;
    }
}
