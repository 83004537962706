import React from "react"
import loadable from "@loadable/component";
import PageLoadingBar from "../components/PageLoadingBar";

import {Route} from "react-router-dom";

// 动态组件
// path = '../views/'
const genLoadableComponent = ({componentName= ''}) => loadable(() => import(`../views/${componentName}.js`), {
    fallback: <PageLoadingBar />
});
const Routes = [
    // {
    //     path: "/",
    //     exact: true,
    //     component: genLoadableComponent({ componentName: "PageIndex" }),
    //     // 新增meta 配置页面Title，以及后面的角色权限配置
    //     meta: {
    //         title: "PandaDaily - 文章、项目检索"
    //     }
    // },
    {
        path: "/",
        component: genLoadableComponent({ componentName: "Home" }),
        meta: {
            title: "PandaDaily - 首页"
        }
    },
    // 文章详情
    {
        path: "/article/:id",
        component: genLoadableComponent({ componentName: "ArticleDetail" }),
        meta: {
            title: "PandaDaily - 文章详情"
        }
    },
    // 关于个人信息
    {
        path: "/about/:id",
        component: genLoadableComponent({ componentName: "About" }),
        meta: {
            title: "PandaDaily - 个人信息"
        }
    },
    {
        path: "/question",
        component: genLoadableComponent({ componentName: "QuestionEcosystem" }),
        meta: {
            title: "PandaDaily - 问答"
        }
    },
    {
        path: "*",
        component: genLoadableComponent({ componentName: "NoMatch" }),
        meta: {
            title: "PandaDaily - 404"
        }
    },
    // {
    //     path: "/category",
    //     component: category,
    //     routes: [
    //         {
    //             path: "/category/project",
    //             component: project
    //         },
    //         {
    //             path: "/category/tech-article",
    //             component: TechArticle
    //         }
    //     ]
    // }
];
// 路由及其子路由嵌套及渲染
export function RouteWithSubRoutes(route) {
    return (
        <Route
            exact={route.exact}
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...Object.assign(props,route.meta)} routes={route.routes} />

            )}
        />
    );
}

export default Routes
